define("discourse/plugins/poll/discourse/components/poll-breakdown-chart", ["exports", "@ember/component", "@ember/object/computed", "@ember/runloop", "@ember/template", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/poll/lib/chart-colors", "discourse/plugins/poll/discourse/components/modal/poll-ui-builder", "@ember/template-factory"], function (_exports, _component, _computed, _runloop, _template, _component2, _decorators, _discourseI18n, _chartColors, _pollUiBuilder, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="poll-breakdown-chart-label">{{@group}}</label>
  <canvas class="poll-breakdown-chart-chart"></canvas>
  */
  {
    "id": "oqA5wA7R",
    "block": "[[[10,\"label\"],[14,0,\"poll-breakdown-chart-label\"],[12],[1,[30,1]],[13],[1,\"\\n\"],[10,\"canvas\"],[14,0,\"poll-breakdown-chart-chart\"],[12],[13]],[\"@group\"],false,[]]",
    "moduleName": "discourse/plugins/poll/discourse/components/poll-breakdown-chart.hbs",
    "isStrictMode": false
  });
  let PollBreakdownChart = _exports.default = (_dec = (0, _component2.classNames)("poll-breakdown-chart-container"), _dec2 = (0, _computed.mapBy)("options", "votes"), _dec3 = (0, _decorators.default)("optionColors", "index"), _dec4 = (0, _decorators.default)("data", "displayMode"), _dec(_class = (_class2 = class PollBreakdownChart extends _component.default {
    constructor() {
      super(...arguments);
      // Arguments:
      _defineProperty(this, "group", null);
      _defineProperty(this, "options", null);
      _defineProperty(this, "displayMode", null);
      _defineProperty(this, "highlightedOption", null);
      _defineProperty(this, "setHighlightedOption", null);
      _initializerDefineProperty(this, "data", _descriptor, this);
      _defineProperty(this, "_optionToSlice", null);
      _defineProperty(this, "_previousHighlightedSliceIndex", null);
      _defineProperty(this, "_previousDisplayMode", null);
    }
    init() {
      super.init(...arguments);
      this._optionToSlice = {};
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      const canvas = this.element.querySelector("canvas");
      this._chart = new window.Chart(canvas.getContext("2d"), this.chartConfig);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this._chart) {
        this._updateDisplayMode();
        this._updateHighlight();
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this._chart) {
        this._chart.destroy();
      }
    }
    colorStyle(optionColors, index) {
      return (0, _template.htmlSafe)(`background: ${optionColors[index]};`);
    }
    chartConfig(data, displayMode) {
      const transformedData = [];
      let counter = 0;
      this._optionToSlice = {};
      data.forEach((votes, index) => {
        if (votes > 0) {
          transformedData.push(votes);
          this._optionToSlice[index] = counter++;
        }
      });
      const totalVotes = transformedData.reduce((sum, votes) => sum + votes, 0);
      const colors = (0, _chartColors.getColors)(data.length).filter((color, index) => data[index] > 0);
      return {
        type: _pollUiBuilder.PIE_CHART_TYPE,
        plugins: [window.ChartDataLabels],
        data: {
          datasets: [{
            data: transformedData,
            backgroundColor: colors,
            // TODO: It's a workaround for Chart.js' terrible hover styling.
            // It will break on non-white backgrounds.
            // Should be updated after #10341 lands
            hoverBorderColor: "#fff"
          }]
        },
        options: {
          plugins: {
            tooltip: false,
            datalabels: {
              color: "#333",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: 2,
              font: {
                family: getComputedStyle(document.body).fontFamily,
                size: 16
              },
              padding: {
                top: 2,
                right: 6,
                bottom: 2,
                left: 6
              },
              formatter(votes) {
                if (displayMode !== "percentage") {
                  return votes;
                }
                const percent = _discourseI18n.default.toNumber(votes / totalVotes * 100.0, {
                  precision: 1
                });
                return `${percent}%`;
              }
            }
          },
          responsive: true,
          aspectRatio: 1.1,
          animation: {
            duration: 0
          },
          // wrapping setHighlightedOption in next block as hover can create many events
          // prevents two sets to happen in the same computation
          onHover: (event, activeElements) => {
            if (!activeElements.length) {
              (0, _runloop.next)(() => {
                this.setHighlightedOption(null);
              });
              return;
            }
            const sliceIndex = activeElements[0].index;
            const optionIndex = Object.keys(this._optionToSlice).find(option => this._optionToSlice[option] === sliceIndex);
            (0, _runloop.next)(() => {
              this.setHighlightedOption(Number(optionIndex));
            });
          }
        }
      };
    }
    _updateDisplayMode() {
      if (this.displayMode !== this._previousDisplayMode) {
        const config = this.chartConfig;
        this._chart.data.datasets = config.data.datasets;
        this._chart.options = config.options;
        this._chart.update();
        this._previousDisplayMode = this.displayMode;
      }
    }
    _updateHighlight() {
      const activeElements = [];
      if (this.highlightedOption) {
        const index = this._optionToSlice[this.highlightedOption];
        if (index !== undefined) {
          activeElements.push({
            datasetIndex: 0,
            index
          });
        }
      }
      this._chart.setActiveElements(activeElements);
      this._chart.update();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "colorStyle", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "colorStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chartConfig", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "chartConfig"), _class2.prototype)), _class2)) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PollBreakdownChart);
});