define("discourse/plugins/poll/discourse/components/poll-breakdown-option", ["exports", "@ember/component", "@ember/object/computed", "@ember/template", "@ember-decorators/component", "discourse/lib/computed", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/poll/lib/chart-colors", "@ember/template-factory"], function (_exports, _component, _computed, _template, _component2, _computed2, _decorators, _discourseI18n, _chartColors, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li
    class="poll-breakdown-option"
    style={{this.colorBackgroundStyle}}
    {{on "mouseover" @onMouseOver}}
    {{on "mouseout" @onMouseOut}}
    role="button"
  >
    <span
      class="poll-breakdown-option-color"
      style={{this.colorPreviewStyle}}
    ></span>
  
    <span class="poll-breakdown-option-count">
      {{#if this.showPercentage}}
        {{this.percent}}%
      {{else}}
        {{@option.votes}}
      {{/if}}
    </span>
    <span class="poll-breakdown-option-text">{{html-safe @option.html}}</span>
  </li>
  */
  {
    "id": "NTR1m1S2",
    "block": "[[[11,\"li\"],[24,0,\"poll-breakdown-option\"],[16,5,[30,0,[\"colorBackgroundStyle\"]]],[24,\"role\",\"button\"],[4,[38,0],[\"mouseover\",[30,1]],null],[4,[38,0],[\"mouseout\",[30,2]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"poll-breakdown-option-color\"],[15,5,[30,0,[\"colorPreviewStyle\"]]],[12],[13],[1,\"\\n\\n  \"],[10,1],[14,0,\"poll-breakdown-option-count\"],[12],[1,\"\\n\"],[41,[30,0,[\"showPercentage\"]],[[[1,\"      \"],[1,[30,0,[\"percent\"]]],[1,\"%\\n\"]],[]],[[[1,\"      \"],[1,[30,3,[\"votes\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"poll-breakdown-option-text\"],[12],[1,[28,[35,2],[[30,3,[\"html\"]]],null]],[13],[1,\"\\n\"],[13]],[\"@onMouseOver\",\"@onMouseOut\",\"@option\"],false,[\"on\",\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/poll/discourse/components/poll-breakdown-option.hbs",
    "isStrictMode": false
  });
  let PollBreakdownOption = _exports.default = (_dec = (0, _component2.tagName)(""), _dec2 = (0, _computed2.propertyEqual)("highlightedOption", "index"), _dec3 = (0, _computed.equal)("displayMode", "percentage"), _dec4 = (0, _decorators.default)("option.votes", "totalVotes"), _dec5 = (0, _decorators.default)("optionsCount"), _dec6 = (0, _decorators.default)("highlighted"), _dec7 = (0, _decorators.default)("highlighted", "optionColors", "index"), _dec(_class = (_class2 = class PollBreakdownOption extends _component.default {
    constructor() {
      super(...arguments);
      // Arguments:
      _defineProperty(this, "option", null);
      _defineProperty(this, "index", null);
      _defineProperty(this, "totalVotes", null);
      _defineProperty(this, "optionsCount", null);
      _defineProperty(this, "displayMode", null);
      _defineProperty(this, "highlightedOption", null);
      _defineProperty(this, "onMouseOver", null);
      _defineProperty(this, "onMouseOut", null);
      _initializerDefineProperty(this, "highlighted", _descriptor, this);
      _initializerDefineProperty(this, "showPercentage", _descriptor2, this);
    }
    percent(votes, total) {
      return _discourseI18n.default.toNumber(votes / total * 100.0, {
        precision: 1
      });
    }
    optionColors(optionsCount) {
      return (0, _chartColors.getColors)(optionsCount);
    }
    colorBackgroundStyle(highlighted) {
      if (highlighted) {
        // TODO: Use CSS variables (#10341)
        return (0, _template.htmlSafe)("background: rgba(0, 0, 0, 0.1);");
      }
    }
    colorPreviewStyle(highlighted, optionColors, index) {
      const color = highlighted ? window.Chart.helpers.getHoverColor(optionColors[index]) : optionColors[index];
      return (0, _template.htmlSafe)(`background: ${color};`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "highlighted", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "showPercentage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "percent", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "optionColors", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "optionColors"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "colorBackgroundStyle", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "colorBackgroundStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "colorPreviewStyle", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "colorPreviewStyle"), _class2.prototype)), _class2)) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PollBreakdownOption);
});